import EposArrowDownIcon from '@/public/icons/common/ArrowDown.icon';
import { InputRadius } from '@/public/styles/spacingConstants';
import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material/styles';
import { createElement } from 'react';

const IconComponent = (props: any) => {
  return createElement(EposArrowDownIcon, { ...props })
}

export const SelectVariant: {
  defaultProps?: ComponentsProps['MuiSelect'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiSelect'];
  variants?: ComponentsVariants['MuiSelect'];
} = {
  defaultProps: {
    size: 'medium',
    IconComponent: IconComponent,
    MenuProps: {
      autoFocus: false,
      PaperProps: {
        eevation: 0,
        sx: {
          boxShadow: 'var(--shadow-secondary-center)',
          borderRadius: '8px',
          marginTop: '0.25rem'
        }
      }
    }
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: InputRadius,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent'
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent'
      },
      '&:hover:not(.Mui-disabled)': {
        boxShadow: 'var(--shadow-secondary-center-for-input)',
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: theme.palette.primary[30]
        }
      },
      '&.Mui-focused': {
        boxShadow: 'var(--shadow-secondary-center-for-input)',
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: theme.palette.primary[10]
        }
      }
    }),
    select: ({theme}) => ({
      '&.MuiInputBase-input': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      }
    }),
    icon: ({theme}) => ({
      width: '1.5rem',
      height: '1.5rem',
      top: `calc(50% - .75rem)`,
    })
  }
};
