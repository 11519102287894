import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { Inter, Roboto } from 'next/font/google';

export const roboto = Roboto({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap'
});

export const TypographyCommon: TypographyOptions = {
  htmlFontSize: 16,
  allVariants: {
    fontFamily: roboto.style.fontFamily,
  },
  h1: {
    fontSize: '3rem',
    lineHeight: 1.167,
    fontWeight: 700
  },
  h2: {
    fontSize: '2.5rem',
    lineHeight: 1.15,
    fontWeight: 700
  },
  h3: {
    fontSize: '2rem',
    lineHeight: 1.1875,
    fontWeight: 700
  },
  h4: {
    fontSize: '1.75rem',
    lineHeight: 1.143,
    fontWeight: 700
  },
  h5: {
    fontSize: '1.5rem',
    lineHeight: 1.167,
    fontWeight: 700
  },
  h6: {
    fontSize: '1.25rem',
    lineHeight: 1.2,
    fontWeight: 700
  },
  h7: {
    fontSize: '1.125rem',
    lineHeight: 1.222,
    fontWeight: 700
  },
  body1: {
    fontSize: '1rem',
    lineHeight: 1.167
  },
  body2: {
    fontSize: '0.875rem',
    lineHeight: 1.2
  },
  body3: {
    fontSize: '1.5rem',
    lineHeight: 1.222
  },
  body4: {
    fontSize: '1.25rem',
    lineHeight: 1.25
  },
  body5: {
    fontSize: '1.125rem',
    lineHeight: 1.286
  },
  body6: {
    fontSize: '0.75rem',
    lineHeight: 1.167
  },
  body7: {
    fontSize: '0.5rem',
    lineHeight: 1.25
  },
  button: {
    fontSize: '1rem'
  }
};

export const TypographyVariant: {
  defaultProps?: ComponentsProps['MuiTypography'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTypography'];
  variants?: ComponentsVariants<Theme>['MuiTypography'];
} = {
  styleOverrides: {},
  variants: [
    {
      props: { color: 'warning' },
      style: ({ theme }) => ({
        color: theme.palette.warning.main
      })
    },
    {
      props: { color: 'info' },
      style: ({ theme }) => ({
        color: theme.palette.info.main
      })
    }
  ]
};

