import { dateCalendarWidth } from '@/public/styles/calendar';
import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';

export const MuiDateCalendar: {
  defaultProps?: ComponentsProps['MuiDateCalendar'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDateCalendar'];
  variants?: ComponentsVariants['MuiDateCalendar'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: dateCalendarWidth,
      height: 'auto',
      minHeight: '12.5rem',

      [theme.breakpoints.down("sm")]: {
        minHeight: '18.75rem',
        width: `calc(${dateCalendarWidth} * 1.75)`,
      },
    }),
  }
}

export const MuiYearCalendar: {
  defaultProps?: ComponentsProps['MuiYearCalendar'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiYearCalendar'];
  variants?: ComponentsVariants['MuiYearCalendar'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: dateCalendarWidth,
      height: 'auto',
      minHeight: '15.5rem',

      [theme.breakpoints.down("sm")]: {
        minHeight: '18.75rem',
        width: `calc(${dateCalendarWidth} * 1.75)`,
      },
    }),
  }
}

export const MuiPickersCalendarHeader: {
  defaultProps?: ComponentsProps['MuiPickersCalendarHeader'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiPickersCalendarHeader'];
  variants?: ComponentsVariants['MuiPickersCalendarHeader'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: '1.125rem',
      minHeight: '1.125rem',
      marginTop: theme.spacing(1.5),
      // width: dateCalendarWidth,

      [theme.breakpoints.down("sm")]: {
        height: '2rem',
        minHeight: '2rem',
      },
    }),
    label: ({ theme }) => ({
      fontSize: '0.875rem',

      [theme.breakpoints.down("sm")]: {
        fontSize: '1.5rem',
      },
    }),
  }
}

export const MuiPickersArrowSwitcher: {
  defaultProps?: ComponentsProps['MuiPickersArrowSwitcher'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiPickersArrowSwitcher'];
  variants?: ComponentsVariants['MuiPickersArrowSwitcher'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: '1rem',

      [theme.breakpoints.down("sm")]: {
        height: '2rem',
      },
    }),
    spacer: ({ theme }) => ({
      width: '.5rem',

      [theme.breakpoints.down("sm")]: {
        width: '1.5rem',
      },
    }),
    button: ({ theme }) => ({
      margin: 0,
      padding: 0,
      width: '1rem',
      height: '1rem',
      fontSize: '0.75rem',
      border: `1px solid ${theme.palette.variant.main}`,

      [theme.breakpoints.down("sm")]: {
        width: '2rem',
        height: '2rem',
        fontSize: '1.5rem',
      },
    })
  }
}

export const MuiDayCalendar: {
  defaultProps?: ComponentsProps['MuiDayCalendar'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDayCalendar'];
  variants?: ComponentsVariants['MuiDayCalendar'];
} = {
  styleOverrides: {
    weekDayLabel: ({ theme }) => ({
      width: '1.5rem',
      height: '1.5rem',

      [theme.breakpoints.down("sm")]: {
        width: '2.5rem',
        height: '2.5rem',
      },
    }),
    weekContainer: ({ theme }) => ({
      margin: 0,
      '& .MuiPickersDay-root': {
        margin: 0,
      }
    }),
    header: ({ theme }) => ({
      '& .MuiDayCalendar-weekDayLabel': {
        margin: 0,

        '&:not(:last-child)': {
          marginRight: theme.spacing(1)
        }
      }
    })
  }
}

export const MuiPickersDay: {
  defaultProps?: ComponentsProps['MuiPickersDay'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiPickersDay'];
  variants?: ComponentsVariants['MuiPickersDay'];
} = {
  styleOverrides: {
    root: ({ theme, ownerState: { selected } }) => ({
      width: '1.5rem',
      height: '1.5rem',
      borderRadius: theme.spacing(1),
      lineHeight: 1,

      [theme.breakpoints.down("sm")]: {
        width: '2.5rem',
        height: '2.5rem',
      },

      '&.MuiPickersDay-today': {
        borderColor: theme.palette.variant.main,
        background: theme.palette.variant.light
      }
    })
  }
}

export const MuiPickersYear: {
  defaultProps?: ComponentsProps['MuiPickersYear'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiPickersYear'];
  variants?: ComponentsVariants['MuiPickersYear'];
} = {
  styleOverrides: {
    root: ({ theme, ownerState: { selected } }) => ({
      width: '3rem',
      height: '2rem',
      borderRadius: theme.spacing(1),
      lineHeight: 1,

      [theme.breakpoints.down("sm")]: {
        width: '4rem',
        height: '3rem',
      },
    })
  }
}

export const MuiPickersSlideTransition: {
  defaultProps?: ComponentsProps['MuiPickersSlideTransition'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiPickersSlideTransition'];
  variants?: ComponentsVariants['MuiPickersSlideTransition'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition': {
        minHeight: '10.5rem',

        [theme.breakpoints.down("sm")]: {
          minHeight: '18.5rem',
        },
      }
    })
  }
}

