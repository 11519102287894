import { SvgPathProps, withMuiSvg } from "../withMuiSvg";

const Icon = ({ contractColor, paletteColor }: SvgPathProps) => {
  return (
    <>
      <g fill="#B4BED7">
        <rect
          width="14.781"
          height="2"
          x="12"
          y="2"
          rx="1"
          transform="rotate(135 12 2)"
        ></rect>
        <rect
          width="14.781"
          height="2"
          rx="1"
          transform="scale(1 -1) rotate(-45 -2.348 -1.16)"
        ></rect>
      </g>
    </>
  );
};

Icon.displayName = "EposClearIcon";

const EposClearIcon = withMuiSvg(Icon, {
  viewBox: "0 0 12 13",
  width: 12,
  height: 13,
  fill: 'none',
  stroke: 'none',
});

export default EposClearIcon;
